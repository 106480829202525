@import "node_modules/bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./assets/css/LUFP_V1/custom.scss";

// @import "./assets/css/LUFP_V1/signature.scss;
// @import "./assets/css/ADV_1.scss";
// @import "./assets/css/documentview.scss";
// @import "./assets/css/followup.scss";
// @import "./assets/css/Idupload.scss";
// @import "./assets/css/loa.scss";
// @import "./assets/css/NotFound.scss";
// @import "./assets/css/Questionnaire.scss";
// @import "./assets/css/split_1.scss";
// @import "./assets/Signature/css/Signature.scss";
// @import "./assets/Thankyou/css/Thankyou.scss";
// @import './assets/css/Modal.scss';
// @import "./assets/Thankyou/css/Thankyou.scss";

body {
  font-family: Poppins, sans-serif !important; ;
  // font-family: 'Poppins', sans-serif !important; 
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}



